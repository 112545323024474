.my-json-editor-1 {

    .vh-50,
    .jsoneditor.jsoneditor-mode-code {
        height: calc(100vh - 285px) !important;

    }
}

.my-json-editor-2 {
    .jsoneditor {
        height: calc(100vh - 400px) !important;
    }

    .jsoneditor-menu {
        display: none
    }

    .jsoneditor-mode-code {
        &::before {
            content: "Add Inbound payload";
            font-weight: bolder;
            color: white;
            display: block;
            top: 0;
            left: 0;
            height: 30px;
            width: 100%;
            z-index: 1;
            /* Add your styles for the new child div here */
            background-color: #3883fa;
            padding-left: 2%;
            padding-top: 1%;
            box-sizing: border-box;
        }
    }
}

.executorClass {
    height: calc(100vh - 440px) !important;
}

.resultExecutorClass {
    height: 10vh !important;
    overflow-y: auto !important;
    color: #2e546f;
    font-weight: 700;
    border-radius: 0px !important;
    margin-top: 10px;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01em;
}

.loginExecutorClass {
    height: calc(80vh - 400px) !important;
    font-weight: bold;
    overflow-y: auto;
}

form.rjsf {
    height: calc(100vh - 300px) !important;

}

/* For mobile phones: */
@media only screen and (max-width: 768px) {

    .vh-50,
    .jsoneditor.jsoneditor-mode-code {
        height: 50vh !important;
    }

    form.rjsf {
        height: 50vh !important;
    }

}




.vertical-scrollable {
    overflow-y: scroll;
}

.form-group .MuiTypography-h5 {
    font-size: 1rem;
    font-family: "Metropolis", sans-serif;
    font-weight: 600;
    line-height: 1.334;
    letter-spacing: 0em;
    margin-bottom: 5px;
    margin-top: 5px;
}

.form-group .MuiButton-textSecondary {
    color: #2e5370;
    text-transform: capitalize;
    border: 1px solid;
    border-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
    margin-bottom: 5px;
}

.form-group .MuiButton-textSecondary:hover {
    background-color: transparent;
    border: 1px solid;
    border-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.makeStyles-root-1 {
    margin-top: 0 !important;
}

.no-link {
    cursor: none;
}

.MuiGrid-root.applications-info.MuiGrid-container {
    text-align: center;
    width: 100%;
}

.MuiGrid-root.applications-info.MuiGrid-container .MuiGrid-root.MuiGrid-item {
    display: flex;
}

.applications-info fieldset.MuiOutlinedInput-notchedOutline,
.form-group fieldset {
    border: 0 none !important;
    border-bottom: 1px solid !important;
    border-radius: 0;
}

.MuiGrid-root.applications-info.MuiGrid-container .MuiGrid-root.MuiGrid-item>* {
    justify-content: center;
    align-self: center;
}

.flex {
    display: flex;
    gap: 20px;
}

.flex label+.MuiInput-formControl {
    margin-top: 5px !important;
    transform: translate(0, 20px) scale(1);
}

.flex .lastChild {
    margin-top: -20px;
}

form.rjsf header * {
    font-weight: normal;
    font-size: 0.85rem;
    padding: 0;
    margin: 0;
    line-height: 1.4em;
}

form.rjsf header {
    padding: 5px 15px;
    background: transparent;
    border-radius: 5px 5px;
}

form.rjsf header div>strong {
    font-weight: bold;
}

form.rjsf header div>p {
    padding-left: 10px;
}

form.rjsf:focus-within input:required:invalid {
    border-bottom: 2px solid hsl(0, 100%, 50%)
}

form.rjsf:focus-within input:required:valid {
    border-bottom: 2px solid inherit
}

form.rjsf:focus-within .invalidfield {
    border-bottom: 2px solid red;
}

.MuiInput-underline:after {
    border-bottom: none !important;
}