.draggingSource {
  opacity: 0.3;
}

.placeholderContainer {
  position: relative;
}

.dropTarget {
  background-color: #e8f0fe;
}

ul {
  list-style: none;
  padding-left: 1rem;
}
