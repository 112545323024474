

// Default variable overrides
$primary: #04b6c3;
$secondary: #173041;
$light: #edf5f6;
$dark: #2e546f;
$error: #f53030;
// $breadcrumb-divider: quote(">");
$enable-negative-margins: true;

$breadcrumb-divider: url('/assets/images/forward_arrow.svg');

  .mw-25{max-width: 250px!important;
  word-break: break-all;
  overflow-wrap: anywhere;
  white-space: break-spaces;}
  body {
    margin: 0;
    font-family: 'Metropolis',sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  :root {
    --bs-body-font-family:'Metropolis',sans-serif
  }
  
  a {
    text-decoration: none;
  }
  .sidebar-nav:after {
    height: 37px;
    background-color: $secondary;
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 99;
    content: "";
}
input[type="text"]::placeholder {  
  /* Firefox, Chrome, Opera */ 
  text-align: left!important; 
} 
.overflow-scroll-y {
  overflow-y: scroll;
}
.st-vh-100{
  height:calc(100vh - 157px) !important;
}

a.text-disabled {
  cursor: default;
}

.centerdivbox{
  @media screen and  (min-width:1500px){
    transform:translate(5%,25%);

  }
}

/* Hide password reveal button in MS Edge */
::-ms-reveal {
  display: none;
}
[class*="MuiBox-root-"]{
  display:none;
}

#relativeTable {
  padding: 0 !important;
}
table.table-striped td {
  color: #000000 !important;
}

#eyepointer:hover{
  cursor: pointer;
}
body .button-danger {
  color: red;
  border-color: red;
}
body .MuiButton-outlinedSecondary {
  color: $secondary;
  border-color: $secondary;
}
body .MuiButton-outlinedSecondary:hover {
  color: #333;
  border-color: #333;
}
body form#myform button[type=submit] {
  display: none!important;
}
form#myform .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-7.MuiGrid-grid-lg-7 > button {
  margin-left: -4.2rem!important;
  margin-top: 1rem!important;
}

.ModalPopUpContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: hsl(192deg 38% 95%);
  border-radius: 20px 20px 20px 20px;
  box-shadow: 24;
  outline: none;
}

.textFieldBorderBottom .MuiInput-root:before {
  border-bottom: none !important;
}