@font-face {
  font-family: 'Metropolis';
  src: local('Metropolis'),
    url(./assets/fonts/WOFF/Metropolis-Regular.woff) format('woff');
  src: local('Metropolis'),
    url(./assets/fonts/WOFF2/Metropolis-Regular.woff2) format('woff2');
  src: local('Metropolis'),
    url(./assets/fonts/EOT/Metropolis-Regular.eot) format('eot');
}

body {
  margin: 0;
  font-family: 'Metropolis', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:root {
  --bs-body-font-family: 'Metropolis', sans-serif;
}

a {
  text-decoration: none;
}
.icon-btn {
  cursor: pointer;
}
.dropdown .clear-style {
  background-color: transparent;
  text-decoration: none;
  color: #000;
}
.clear-style.dropdown-toggle:hover,
.clear-style.dropdown-toggle:active,
.clear-style.dropdown-toggle:focus {
  background-color: transparent;
  color: #000;
}
.sidebar-nav .list-group-item {
  background-color: transparent;
}
.sidebar-nav .list-group-item a {
  color: white;
  display: block;
}
.list-group-item.active {
  background-color: #04b6c3;
}
.sidebar-nav a svg {
  margin-top: -5px;
  margin-right: 5px;
  width: 1.43em;
  height: 1.43em;
}
.breadcrumb-item a,
.font-18 {
  font-size: 1.15em;
  font-weight: bold;
}
.breadcrumb-item + .breadcrumb-item::before {
  font-weight: bold;
}

.row-flex {
  display: flex;
  flex-wrap: wrap;
}
.rounded-start-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.border-bottom-primary {
  border: 0 none;
  border-bottom: 2px solid #04b6c3 !important;
  border-radius: 0;
}
.align-right {
  text-align: right;
}
.form-label {
  color: var(--bs-primary);
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
  font-weight: 400;
}
.menuButton {
  z-index: 9;
  cursor: pointer;
  top: 4.8rem;
}
.minimize-nav ul li > a > span {
  display: none !important;
}
.minimize-nav {
  width: auto;
}
.card.rounded.shadow-sm,
.card.rounded.shadow {
  border-radius: 0.75rem !important;
}
li.list-group-item:hover {
  background-color: #04b6c3;
}
.w-15 {
  width: 2rem;
}
